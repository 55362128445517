import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  Ref,
} from "react";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

export type ButtonVariantProps = VariantProps<typeof buttonVariants>;

interface ButtonPropsAsAnchor
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    ButtonVariantProps {
  href: string;
}

interface ButtonPropsAsButton
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonVariantProps {
  href?: never;
}

const buttonVariants = cva(
  "relative disabled:cursor-not-allowed inline-flex items-center outline-none duration-300 ring-offset-2 rounded-xl text-center font-medium md:text-left transition-[background-color,box-shadow,opacity,color]",
  {
    variants: {
      intent: {
        primary:
          "bg-black text-white ring-white hover:bg-[#222] focus-visible:bg-[#222] focus-visible:ring-1",
        secondary:
          "bg-violet-muted text-black font-medium ring-black hover:bg-[#d6cef6] focus-visible:bg-[#d6cef6] focus-visible:ring-1",
        plain:
          "bg-transparent relative after:left-0 after:right-0 after:top-full hover:after:-translate-y-4 after:transition-transform after:-translate-y-3 after:absolute after:h-px focus-visible:after:h-[3px] after:bg-white",
        transparent:
          "bg-transparent relative hover:bg-white focus-visible:bg-white hover:text-black focus-visible:text-black",
        white:
          "bg-white text-black ring-black hover:bg-[#d6cef6] focus-visible:bg-[#d6cef6] focus-visible:ring-1",
        ghostWhite:
          "bg-transparent text-white border-2 border-white hover:bg-white focus-visible:bg-white hover:text-black focus-visible:text-black focus-visible:ring-1",
        pro: "bg-pro text-black hover:bg-pro-light focus-visible:bg-pro-light border border-white-opaque",
      },
      isLoading: {
        true: "opacity-50 cursor-not-allowed",
      },
      size: {
        small: "py-2 text-md",
        regular: "md:text-lg text-md",
      },
    },
    defaultVariants: {
      intent: "primary",
      size: "regular",
    },
    compoundVariants: [
      {
        intent: ["primary", "secondary", "white", "pro"],
        size: "small",
        className: "px-4 py-2",
      },
      {
        intent: ["primary", "secondary", "white", "pro"],
        size: "regular",
        className: "px-6 py-3",
      },
      {
        intent: "plain",
        size: "regular",
        className: "py-2",
      },
      {
        intent: "ghostWhite",
        size: "regular",
        className: "px-5 py-4",
      },
      {
        intent: "ghostWhite",
        size: "small",
        className: "px-3 py-[6px]",
      },
      {
        intent: "transparent",
        className: "px-3 py-2",
      },
    ],
  },
);

// @TODO: Remove duplicate button component.
// Didn't move next variant to UI yet, because the <link>
// component of next should be somewhat dynamic (eg not used in astro)
// apart from that button components are the same right now.

export type ButtonProps = ButtonPropsAsAnchor | ButtonPropsAsButton;

export const Button = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonPropsAsAnchor | ButtonPropsAsButton
>(({ children, className, isLoading, intent, size, ...restProps }, ref) => {
  const classes = buttonVariants({ intent, isLoading, size });

  if (typeof restProps.href === "string") {
    const LinkEl = "a";
    return (
      <LinkEl
        ref={ref as Ref<HTMLAnchorElement>}
        className={twMerge(classes, className)}
        {...restProps}
      >
        {isLoading && (
          <svg
            aria-hidden="true"
            className="mr-2 h-4 w-4 animate-spin fill-white text-black"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        )}
        {children}
      </LinkEl>
    );
  }

  return (
    <button
      ref={ref as Ref<HTMLButtonElement>}
      disabled={isLoading ?? false}
      className={twMerge(classes, className)}
      {...restProps}
    >
      {isLoading && (
        <svg
          aria-hidden="true"
          className="mr-2 h-4 w-4 animate-spin fill-white text-black"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      )}
      {children}
    </button>
  );
});

Button.displayName = "Button";

type ButtonGroupProps = {
  children: React.ReactNode;
  gaps?: string;
  className?: string;
};

export const ButtonGroup = ({
  children,
  gaps,
  className,
}: ButtonGroupProps) => (
  <div
    className={twMerge(
      "not-prose flex flex-wrap",
      gaps ? gaps : "gap-2 md:gap-6",
      className,
    )}
  >
    {children}
  </div>
);
